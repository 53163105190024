import { asyncRunOnReadyState, monitorError, PageExitReason } from '@datadog/browser-core';
import { getSessionReplayLink } from '../domain/getSessionReplayLink';
export function createPostStartStrategy(configuration, lifeCycle, sessionManager, viewHistory, loadRecorder, getOrCreateDeflateEncoder) {
  let status = 0 /* RecorderStatus.Stopped */;
  let stopRecording;
  lifeCycle.subscribe(8 /* LifeCycleEventType.SESSION_EXPIRED */, () => {
    if (status === 2 /* RecorderStatus.Starting */ || status === 3 /* RecorderStatus.Started */) {
      stop();
      status = 1 /* RecorderStatus.IntentToStart */;
    }
  });
  // Stop the recorder on page unload to avoid sending records after the page is ended.
  lifeCycle.subscribe(10 /* LifeCycleEventType.PAGE_EXITED */, pageExitEvent => {
    if (pageExitEvent.reason === PageExitReason.UNLOADING) {
      stop();
    }
  });
  lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_RENEWED */, () => {
    if (status === 1 /* RecorderStatus.IntentToStart */) {
      start();
    }
  });
  const doStart = async () => {
    const [startRecordingImpl] = await Promise.all([loadRecorder(), asyncRunOnReadyState(configuration, 'interactive')]);
    if (status !== 2 /* RecorderStatus.Starting */) {
      return;
    }
    const deflateEncoder = getOrCreateDeflateEncoder();
    if (!deflateEncoder || !startRecordingImpl) {
      status = 0 /* RecorderStatus.Stopped */;
      return;
    }
    ;
    ({
      stop: stopRecording
    } = startRecordingImpl(lifeCycle, configuration, sessionManager, viewHistory, deflateEncoder));
    status = 3 /* RecorderStatus.Started */;
  };
  function start(options) {
    const session = sessionManager.findTrackedSession();
    if (canStartRecording(session, options)) {
      status = 1 /* RecorderStatus.IntentToStart */;
      return;
    }
    if (isRecordingInProgress(status)) {
      return;
    }
    status = 2 /* RecorderStatus.Starting */;
    // Intentionally not awaiting doStart() to keep it asynchronous
    doStart().catch(monitorError);
    if (shouldForceReplay(session, options)) {
      sessionManager.setForcedReplay();
    }
  }
  function stop() {
    if (status === 3 /* RecorderStatus.Started */) {
      stopRecording === null || stopRecording === void 0 ? void 0 : stopRecording();
    }
    status = 0 /* RecorderStatus.Stopped */;
  }
  return {
    start,
    stop,
    getSessionReplayLink() {
      return getSessionReplayLink(configuration, sessionManager, viewHistory, status !== 0 /* RecorderStatus.Stopped */);
    },
    isRecording: () => status === 3 /* RecorderStatus.Started */
  };
}
function canStartRecording(session, options) {
  return !session || session.sessionReplay === 0 /* SessionReplayState.OFF */ && (!options || !options.force);
}
function isRecordingInProgress(status) {
  return status === 2 /* RecorderStatus.Starting */ || status === 3 /* RecorderStatus.Started */;
}
function shouldForceReplay(session, options) {
  return options && options.force && session.sessionReplay === 0 /* SessionReplayState.OFF */;
}
