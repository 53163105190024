<div>
  <p-toast></p-toast>
  <p-toast key="support">
    <ng-template let-message pTemplate="message">
      <div class="p-toast-message-text">
        <div class="p-toast-summary">{{ message.summary }}</div>
        <div class="p-toast-detail">{{ message.detail }}</div>
        <div class="flex">
          <a class="text-xs mt-2 text-blue-light cursor-pointer" (click)="onContactSupport(message)">More Details</a>
        </div>
      </div>
    </ng-template>
  </p-toast>
  <p-confirmPopup></p-confirmPopup>
  <div class="fixed w-full z-50 bg-blue-light">
    <lfx-header-v2
      id="lfx-header"
      userefreshtoken="true"
      use-request-id="true"
      product="LF Platform Apps"
      docslink="https://docs.linuxfoundation.org/lfx/project-control-center-v2"
      supportlink="https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4/create/358"
      communitylink="https://community.lfx.dev"></lfx-header-v2>
  </div>
  <div id="main-content" class="pt-[60px]" *ngIf="user$ | async as user">
    <div id="app">
      <div class="app-body">
        <lfx-navigation [projectID]="projectID$ | async" [currentProjectID]="currentProjectID$ | async" *ngIf="!hideSidebar"></lfx-navigation>
        <div class="router-container" [class.overflow-x-hidden]="!isHealthMetrics">
          <div class="h-full flex flex-col justify-between pb-2">
            <div [ngClass]="{ container: !hideSidebar }">
              <div>
                <ng-container *ngIf="!hideHeader">
                  <lfx-common-header
                    *ngIf="project$ | async; else loading"
                    [projectID]="projectID$ | async"
                    [currentProjectID]="currentProjectID$ | async"></lfx-common-header>
                </ng-container>
                <router-outlet></router-outlet>
              </div>
            </div>
            <lfx-footer></lfx-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="flex flex-col gap-4 mb-4">
    <div class="flex items-center gap-4 pt-3">
      <p-skeleton width="150px" height="34px"></p-skeleton>
      <p-skeleton width="25px" shape="circle" height="25px"></p-skeleton>
      <p-skeleton width="75px" height="24px"></p-skeleton>
    </div>
    <hr />
  </div>
</ng-template>
