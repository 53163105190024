<div class="project-header" *ngIf="project$ | async as project" [class.health-metric-header]="isHealthMetrics">
  <div class="flex justify-between">
    <div class="flex items-center gap-4 flex-wrap">
      <!-- <ng-container *ngIf="!isHealthMetrics"> -->
      <div class="dropdown" [formGroup]="form" *ngIf="foundation$ | async; else loadingDropdown">
        <!-- All other breadcrumbs should be a dropdown of projects -->
        <p-treeSelect
          formControlName="project"
          [options]="childProjects"
          placeholder="Select a sub-project"
          styleClass="project-dropdown"
          [filter]="true"
          filterBy="label,data.Slug"
          appendTo="body"
          (onShow)="onShowProjects($event)"
          filterPlaceholder="Search sub-projects">
        </p-treeSelect>
      </div>
      <!-- </ng-container> -->
      <div class="bookmark">
        <i
          class="fa-star fa-solid"
          *ngIf="favoriteProject$ | async as favoriteProject"
          appendTo="body"
          pTooltip="Bookmark"
          [ngClass]="favoriteProject && favoriteProject.length ? 'active' : ''"
          (click)="onToggleFavorite()"></i>
      </div>
      <div class="project-status">
        <span [class]="project.Status | lowercase" [pTooltip]="project.Status | projectStageInfo" appendTo="body">{{
          project.Status === 'Prospect' ? 'Draft' : project.Status
        }}</span>
      </div>
    </div>
    <ng-container *ngIf="!isHealthMetrics">
      <div class="flex flex-col lg:flex-row gap-2 items-center" *ngIf="permissions$ | async">
        <button class="cta-button" *ngIf="canCreateProjects" (click)="onCreate()">
          <i class="fa-light fa-plus text-blue-light"></i>
          <span>Add Project</span>
        </button>
        <button class="cta-button" routerLink="/all-projects">
          <i class="fa-light fa-layer-group text-blue-light"></i>
          <span>View All Projects</span>
        </button>
        <button *ngIf="hasShowAuditLog" class="cta-button" (click)="toggleAuditLog()">
          <i class="fa-light fa-file text-blue-light"></i>
          <span>View Log</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="isHealthMetrics && showHealthTimeRange">
      <div class="flex flex-col items-end">
        <span class="text-13 font-robotoSlabBold font-bold">{{ pageTitle }}</span>
        <lfx-health-metric-time-range></lfx-health-metric-time-range>
      </div>
    </ng-container>
  </div>
</div>
<div class="draft-warning-container" *ngIf="project.Status === 'Prospect'">
  <p-messages severity="warn">
    <ng-template pTemplate>
      <div class="w-full flex justify-between items-center">
        <div class="flex gap-4 items-center">
          <i class="fa-solid fa-triangle-exclamation text-22"></i>
          <p class="text-13">
            This project is in a draft stage and some services will not be available. Projects in draft stage are regularly reviewed and approved in 3 to 5
            business days.
          </p>
        </div>
        <a pButton class="p-button-secondary" [href]="escalateReviewLink" target="_blank">Escalate Review</a>
      </div>
    </ng-template>
  </p-messages>
</div>

<p-sidebar [(visible)]="showAuditLog" [blockScroll]="true" position="right" styleClass="!w-3/4">
  <ng-template pTemplate="header">ACTIVITY LOG</ng-template>
  <ng-template pTemplate="content">
    <lfx-committee-audit-log *ngIf="hasCommitteeAuditLog"></lfx-committee-audit-log>
    <lfx-activity-log *ngIf="hasProjectAuditLog"></lfx-activity-log>
  </ng-template>
</p-sidebar>

<ng-template #loadingDropdown>
  <p-skeleton width="150px" height="34px"></p-skeleton>
</ng-template>
