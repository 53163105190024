<div class="activity-log-container">
  <ng-container *ngIf="committee$ | async"></ng-container>
  <div class="flex justify-between mb-6">
    <div class="text-lg font-bold">{{ (committee && committee.Name) || '' }}</div>
    <div class="flex justify-end gap-4 items-center">
      <div class="flex" [formGroup]="form">
        <p-dropdown [options]="rangeOptions" formControlName="period" placeholder="Range" appendTo="body" (onChange)="onChange()"></p-dropdown>
      </div>
      <i
        *ngIf="!hasError && auditLogTableData.length > 0"
        title="Download activity Log"
        class="fa-regular fa-arrow-down-to-line text-xl cursor-pointer"
        (click)="onExport()"></i>
    </div>
  </div>

  <div *ngIf="committeesAuditLogs$ | async; else auditLoading">
    <div class="overflow-auto" [ngStyle]="{ height: tableHeight }">
      <p-table
        [value]="auditLogTableData"
        rowGroupMode="rowspan"
        groupRowsBy="createdAt"
        sortField="createdAt"
        [sortOrder]="-1"
        sortMode="single"
        [paginator]="true"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [rows]="5">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex items-center gap-1">
                Date & Time
                <p-columnFilter type="date" field="filterDate" display="menu" [showMatchModes]="true" [showOperator]="false" [showAddButton]="false">
                </p-columnFilter>
              </div>
            </th>
            <th *ngIf="!committeeId">
              <div class="flex items-center gap-1">
                Committee
                <p-columnFilter
                  type="text"
                  field="committeeName"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                Type
                <p-columnFilter field="type" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="typeFilter" (onChange)="filter($event.value)" placeholder="Search Type" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <div class="text-xs">{{ option.value }}</div>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                Action
                <p-columnFilter field="action" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="actionFilter" (onChange)="filter($event.value)" placeholder="Search Action" [showClear]="true">
                      <ng-template let-option pTemplate="item">
                        <div class="text-xs">{{ option.value }}</div>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                Members
                <p-columnFilter
                  type="text"
                  field="memberName"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                Field
                <p-columnFilter
                  type="text"
                  field="field"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                From
                <p-columnFilter
                  type="text"
                  field="from"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                To
                <p-columnFilter type="text" field="to" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex items-center gap-1">
                User
                <p-columnFilter field="auditUser" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="filterUsers" placeholder="Search User" (onChange)="filter($event.value)" optionLabel="Name">
                      <ng-template let-option pTemplate="item">
                        <div class="flex gap-2 items-center">
                          <p-avatar
                            *ngIf="option.Avatar && option.Avatar !== ''"
                            [image]="option.Avatar"
                            (onImageError)="onImageError($event)"
                            shape="circle"></p-avatar>
                          <p-avatar *ngIf="!option.Avatar || option.Avatar === ''" [label]="option.Name | initial" shape="circle"></p-avatar>
                          <div class="text-xs">{{ option.Name }}</div>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-auditLog let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
          <tr>
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
              <div class="w-24">{{ auditLog.createdAt | date: 'dd MMM y h:mm a' }}</div>
            </td>
            <td *ngIf="!committeeId">
              {{ auditLog.committeeName }}
            </td>
            <td>
              {{ auditLog.type }}
            </td>
            <td>
              <div class="w-20">{{ auditLog.action | titlecase }}</div>
            </td>
            <td>
              <div class="w-20">{{ auditLog.memberName }}</div>
            </td>
            <td>{{ auditLog.field | titlecase }}</td>
            <td>
              <!-- Added txt-ellipse class to show only limited length of chars string if it is without space else show all the text data-->
              <div [innerHTML]="auditLog.from"></div>
            </td>
            <td>
              <div [innerHTML]="auditLog.to"></div>
            </td>
            <td>
              <ng-container [ngTemplateOutlet]="memberInfo" [ngTemplateOutletContext]="{ auditUser: auditLog.auditUser }"></ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">
              <div class="text-center">This project has no activity log</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #memberInfo let-row="auditUser">
  <div class="flex flex-row justify-start items-center gap-2">
    <p-avatar
      *ngIf="row.Avatar && row.Avatar !== ''"
      [title]="row.UserName || '-'"
      [image]="row.Avatar"
      styleClass="cursor-pointer"
      (onImageError)="onImageError($event)"
      shape="circle"></p-avatar>
    <p-avatar
      [title]="row.UserName || '-'"
      *ngIf="!row.Avatar || row.Avatar === ''"
      [label]="row.FirstName | initial"
      shape="circle"
      styleClass="cursor-pointer"></p-avatar>
    <div>
      <div class="user" [ngClass]="{ 'no-user': row.Name === '-' }">{{ row.Name }}</div>
      <div [title]="row.Email || '-'" class="truncate w-44">{{ row.Email }}</div>
    </div>
  </div>
</ng-template>

<ng-template #auditLoading>
  <div *ngIf="hasError" class="text-sm text-red text-center">No record found</div>
  <ng-container *ngIf="!hasError">
    <p-table [value]="[1, 2]" [scrollable]="true" breakpoint="1024px">
      <ng-template pTemplate="header">
        <tr>
          <th>Date & Time</th>
          <th *ngIf="!committeeId">Committee</th>
          <th>Type</th>
          <th>Action</th>
          <th>Members</th>
          <th>Field</th>
          <th>From</th>
          <th>To</th>
          <th>User</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>
          <td><p-skeleton height="15px"></p-skeleton></td>
          <td *ngIf="!committeeId">
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <p-skeleton height="15px"></p-skeleton>
          </td>
          <td>
            <div class="grid gap-2">
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
            </div>
          </td>
          <td>
            <div class="grid gap-2">
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
              <p-skeleton height="15px"></p-skeleton>
            </div>
          </td>
          <td>
            <ng-container [ngTemplateOutlet]="memberLoading"></ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</ng-template>

<ng-template #memberLoading>
  <div class="flex flex-row justify-start items-center gap-2">
    <p-skeleton shape="circle" size="2rem"></p-skeleton>
    <div class="grid gap-2">
      <p-skeleton width="150px" height="15px"></p-skeleton>
      <p-skeleton width="150px" height="15px"></p-skeleton>
    </div>
  </div>
</ng-template>
