import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserService } from '@services/user.service';
import { NavigationItem } from 'lfx-pcc';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'lfx-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TooltipModule]
})
export class NavigationItemComponent {
  @Input() public item: NavigationItem;
  @Input() public showFavorite: boolean = false;

  @Output() public readonly favoriteUpdated: EventEmitter<void> = new EventEmitter<void>();

  public constructor(private userService: UserService) {}

  public getSlug(item: NavigationItem): string {
    return `/project/${item.id}`;
  }

  public toggleFavorite(): void {
    this.item.favorite = !this.item.favorite;

    if (this.item.favorite) {
      this.userService.favoriteProjects.push({
        ID: this.item.id,
        Name: this.item.name,
        Slug: this.item.slug
      });
    }

    if (!this.item.favorite) {
      this.userService.favoriteProjects = this.userService.favoriteProjects.filter((project) => project.ID !== this.item.id);
    }

    this.userService.updateUserFavoriteProjects();
  }
}
