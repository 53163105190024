"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lfxPreset = void 0;
const component_tokens_1 = require("../tokens/component.tokens");
const primitive_tokens_1 = require("../tokens/primitive.tokens");
const semantic_tokens_1 = require("../tokens/semantic.tokens");
exports.lfxPreset = {
  component: component_tokens_1.componentTokens,
  primitive: primitive_tokens_1.primitiveTokens,
  semantic: semantic_tokens_1.semanticTokens
};