<div class="search-input">
  <div class="search-wrapper" [formGroup]="form" *ngIf="project$ | async as project; else loadingSearchInput">
    <input
      formControlName="search"
      pInputText
      placeholder="Search"
      (click)="onClickSearch($event)"
      (focus)="onClickSearch($event)"
      (keyup)="onClickSearch($event)"
      [value]="project.Name"
      #searchInput />
  </div>
  <div class="relative">
    <p-overlayPanel #op appendTo="body" styleClass="global-search-panel" [showCloseIcon]="false">
      <div class="global-search">
        <div class="recent-projects" *ngIf="!hasSearchValue">
          <div class="result-container">
            <h4>Recently Viewed</h4>
            <div class="result-container">
              <div *ngIf="recentlyViewed$ | async as recentlyViewed; else loading">
                <ul>
                  <li
                    *ngFor="let project of recentlyViewed"
                    [routerLink]="['/project', project.ID]"
                    class="flex justify-between items-center gap-1"
                    (click)="onSelect()">
                    <a [routerLink]="['/project', project.ID]">
                      {{ project.Name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="search-results" *ngIf="hasSearchValue">
          <p-tabView>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex justify-between items-center gap-1">
                  <span>Projects</span>
                  <span *ngIf="!loadingProjects" class="text-gray-818">({{ projectsCount }})</span>
                  <i class="fa-light fa-circle-notch fa-spin" *ngIf="loadingProjects"></i>
                </div>
              </ng-template>
              <div class="result-container">
                <div *ngIf="searchProjects$ | async as projects; else loading">
                  <ul>
                    <li *ngFor="let project of projects" class="level-{{ project.level }}" [routerLink]="['/project', project.ID]" (click)="onSelect()">
                      <a [routerLink]="['/project', project.ID]">{{ project.Name }}</a>
                    </li>
                    <li class="empty" *ngIf="!projects || projects.length === 0">No projects found</li>
                  </ul>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex justify-between items-center gap-1">
                  <span>Domains</span>
                  <div class="v1-link">
                    <p-tag pTooltip="This service will redirect you to PCC V1, and will soon be migrated.">V1</p-tag>
                  </div>
                  <span *ngIf="!loadingDomains" class="text-gray-818">({{ domainsCount }})</span>
                  <i class="fa-light fa-circle-notch fa-spin" *ngIf="loadingDomains"></i>
                </div>
              </ng-template>
              <div class="result-container">
                <div *ngIf="searchDomains$ | async as domains; else loading" class="result-container">
                  <ul>
                    <li *ngFor="let domain of domains">
                      <a href="{{ originalSiteURL }}/project/{{ domain.project_id }}/operations/domains" class="flex flex-col gap-1">
                        <span>{{ domain.domain_name }}</span>
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ domain.project_name }}
                        </span>
                      </a>
                    </li>
                    <li class="empty" *ngIf="!domains || domainsCount === 0">No domains found</li>
                  </ul>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="false">
              <!-- TODO: Reenable once we figure out what this actually does -->
              <ng-template pTemplate="header">
                <div class="flex justify-between items-center gap-2">
                  <i class="pi pi-calendar"></i>
                  <span>Users</span>
                  <span *ngIf="!loadingUsers">({{ usersCount }})</span>
                  <i class="fa-light fa-circle-notch fa-spin" *ngIf="loadingUsers"></i>
                </div>
              </ng-template>
              <div class="result-container">
                <div *ngIf="searchUser$ | async as users; else loading" class="result-container">
                  <ul>
                    <li *ngFor="let user of users">
                      <div class="flex gap-3 items-center">
                        <div class="flex flex-col items-start h-max">
                          <div class="flex h-fit">
                            <p-avatar
                              *ngIf="user.LogoURL"
                              [image]="user.LogoURL"
                              size="normal"
                              shape="circle"
                              styleClass="items-center flex"
                              class="items-center flex"></p-avatar>
                            <p-avatar
                              *ngIf="!user.LogoURL"
                              [label]="user.FirstName || user.Email | initial"
                              size="normal"
                              shape="circle"
                              styleClass="items-center flex"
                              class="items-center flex"></p-avatar>
                          </div>
                        </div>
                        <div class="flex flex-col">
                          <a class="text-primary cursor-pointer break-all">{{ user.FirstName }} {{ user.LastName }}</a>
                          <div *ngFor="let email of user.Emails" class="overflow-ellipsis whitespace-nowrap overflow-hidden break-all">
                            {{ email.EmailAddress }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="empty" *ngIf="!users || users.length === 0">No users found</li>
                  </ul>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>

<ng-template #loading>
  <div class="global-search">
    <div class="search-results">
      <div class="result-container">
        <ul>
          <li class="empty"><p-skeleton width="150px" height="20px"></p-skeleton></li>
          <li class="empty"><p-skeleton width="150px" height="20px"></p-skeleton></li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingSearchInput>
  <div class="search-wrapper" [formGroup]="form">
    <input
      formControlName="search"
      pInputText
      placeholder="Search"
      (click)="onClickSearch($event)"
      (focus)="onClickSearch($event)"
      (keyup)="onClickSearch($event)"
      #searchInput />
  </div>
</ng-template>
