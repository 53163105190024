import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProjectService } from '@app/shared/services/project.service';
import { flattenSummaryData } from '@app/shared/utils/helper';
import { Project, ProjectSummary } from 'lfx-pcc';
import { cloneDeep, compact, isEmpty, map, orderBy } from 'lodash';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { Observable } from 'rxjs';

@Component({
  selector: 'lfx-search-projects',
  standalone: true,
  imports: [CommonModule, AutoCompleteModule, ReactiveFormsModule],
  templateUrl: './search-projects.component.html',
  styleUrls: ['./search-projects.component.scss']
})
export class SearchProjectsComponent {
  @ViewChild(AutoComplete, { static: true }) public readonly autoComplete: AutoComplete | null = null;

  @Output() public readonly selectProject: EventEmitter<Project> = new EventEmitter<Project>();

  public form: FormGroup;
  public project$: Observable<Project | null>;
  public projectsRaw: ProjectSummary[] = [];
  public projects: ProjectSummary[] = [];

  private fb: FormBuilder = inject(FormBuilder);
  private projectService: ProjectService = inject(ProjectService);

  public constructor() {
    this.form = this.fb.group({
      search: ['']
    });
  }

  public onSelect(event: AutoCompleteSelectEvent): void {
    const project = event.value as Project;
    if (project && project.ID) {
      this.selectProject.emit(project);
      this.autoComplete?.clear();
    }
  }

  public onSearch(event: AutoCompleteCompleteEvent): void {
    const query = event.query && event.query.trim();
    if (this.projectsRaw.length === 0) {
      this.fetchProjectsFromSummary().subscribe((projects: ProjectSummary[]) => {
        this.projectsRaw = projects;
        this.handleSearch(query);
      });
    } else {
      this.handleSearch(query);
    }
  }

  private fetchProjectsFromSummary(): Observable<ProjectSummary[]> {
    const params = new HttpParams({ fromObject: { pageSize: '9999', excludeCategories: true, view: 'pcc' } });
    return this.projectService.getProjectSummary(params);
  }

  private handleSearch(searchValue: string) {
    this.projects = flattenSummaryData(this.filterProjects(cloneDeep(this.projectsRaw), searchValue));
  }

  private filterProjects = (projects: ProjectSummary[], search: string): ProjectSummary[] =>
    orderBy(
      compact(
        map(projects, (p) => {
          if (
            (search && p.Name.toLocaleLowerCase().includes(search.toLowerCase())) ||
            (search && p.Slug && p.Slug.toLocaleLowerCase().includes(search.toLowerCase()))
          ) {
            // loop through this parent's projects and filter that as well
            if (p.Projects) {
              p.Projects = this.filterProjects(p.Projects, search);
            }
            return p;
          } else if (p.Projects) {
            const filteredList: ProjectSummary[] = this.filterProjects(p.Projects, search);
            if (isEmpty(filteredList)) {
              return null;
            }
            p.Projects = filteredList;
            return p;
          }
          return null;
        })
      ),
      'Name'
    );
}
