import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

@Component({
  selector: 'lfx-contact-support',
  standalone: true,
  imports: [CommonModule, VirtualScrollerModule, ButtonModule, ClipboardModule, TooltipModule],
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  public copyText: string = 'Copy';

  private dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);

  public constructor() {}

  public ngOnInit(): void {}

  public get errorStack(): string {
    return this.dialogConfig?.data?.error || '';
  }

  public onClickCopy(): void {
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy';
    }, 2000);
  }
}
