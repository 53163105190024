import { CommonModule, Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ProjectService } from '@app/shared/services/project.service';
import { UserService } from '@app/shared/services/user.service';
import { flattenSummaryData } from '@app/shared/utils/helper';
import { environment } from '@environments/environment';
import { FavoriteProject, Navigation, NavigationItem, Permission, Project, ProjectPages, ProjectSummary, User } from 'lfx-pcc';
import { orderBy } from 'lodash';
import { SkeletonModule } from 'primeng/skeleton';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';

import { GlobalSearchComponent } from '../global-search/global-search.component';
import { SearchProjectsComponent } from '../search-projects/search-projects.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';

@Component({
  selector: 'lfx-navigation',
  standalone: true,
  imports: [
    CommonModule,
    SearchProjectsComponent,
    ReactiveFormsModule,
    RouterModule,
    NavigationItemComponent,
    SkeletonModule,
    TagModule,
    TooltipModule,
    GlobalSearchComponent
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input() public projectID: string | null;
  @Input() public currentProjectID: string | null;

  public project$: Observable<Project | null>;
  public user$: Observable<User>;
  public navItems: Navigation[] = [];
  public currentPath: string = '';
  public linuxFoundationSFID: string = environment.linuxFoundationSFID;
  public favoriteProjects$: Observable<NavigationItem[]>;
  public favoriteProjects: FavoriteProject[] = [];
  public favoriteProjectsID: string;
  public favoriteProjectsShowMore: boolean = false;
  public favoriteProjectsCollapsed: boolean = true;
  public globalCollapsed: boolean = true;
  public assignedProjects$: Observable<NavigationItem[]>;
  public assignedProjects: NavigationItem[] | null = null;
  public assignedProjectsShowMore: boolean = false;
  public assignedProjectsCollapsed: boolean = true;
  public sidebarExpanded: boolean = false;
  public originalSiteURL: string = `${environment.originalSiteURL}?v1=true`;
  public permissions$: Observable<Permission>;

  private location: Location = inject(Location);
  private projectService: ProjectService = inject(ProjectService);
  private userService: UserService = inject(UserService);
  private router: Router = inject(Router);

  public constructor() {}

  public ngOnInit(): void {
    this.currentPath = this.location.path();
    this.permissions$ = this.userService.permissions$;
    if (this.projectID) {
      this.setProject();
    }

    this.initializeHomeSidebar();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectID && changes.projectID.currentValue) {
      this.currentPath = this.location.path();

      if (this.projectID) {
        this.setProject();
        this.setNavItemsActiveState();
      }
    }

    this.initializeHomeSidebar();
  }

  public onSelectProject(project: Project): void {
    if (project) {
      this.router.navigate([`/project/${project.ID}`], { onSameUrlNavigation: 'reload' });
    }
  }

  public toggleDropdown(nav: Navigation): void {
    this.navItems.forEach((item: Navigation) => {
      item.active = item.name === nav.name ? !item.active : false;
    });
  }

  public getNavGroupMaxHeight(groupLength: ProjectPages[], groupCollapsed: boolean | undefined): string {
    if (groupCollapsed) {
      return '0';
    }

    return (groupLength.length + 1) * 32 + 45 + 'px';
  }

  public setNavItemsActiveState(): void {
    if (this.projectService.currentProject?.ID) {
      this.toggleCurrentService();
    } else {
      this.navItems = [{ name: '', active: false, items: [], label: '', url: '' }];
    }
  }

  public setNavItems(): void {
    this.navItems = this.projectService.getProjectPageList(this.projectService.currentProject.ID).map((item) => {
      if (item.name === 'Operations') {
        const operationsOrder = ['Project Definition', 'Membership', 'Legal', 'Domains', 'Cloud Providers', 'Permissions'];
        item.items = orderBy(item.items, (i) => operationsOrder.indexOf(i.name));
      }
      if (item.name === 'Collaboration') {
        const collaborationsOrder = ['Committees', 'Meetings', 'Mailing Lists', 'Wiki', 'Issue Tracker', 'Voting', 'Surveys'];
        item.items = orderBy(item.items, (i) => collaborationsOrder.indexOf(i.name));
      }
      if (item.name === 'Development') {
        const developmentOrder = ['Source Control', 'CI', 'Distribution'];
        item.items = orderBy(item.items, (i) => developmentOrder.indexOf(i.name));
      }
      if (item.name === 'LFX Tools') {
        item.hide =
          this.projectService.currentProject.Status.toLowerCase() !== 'active' &&
          this.projectService.currentProject.Status.toLowerCase() !== 'formation - engaged';
        const toolsOrder = ['Security', 'EasyCLA', 'Mentorship', 'Crowdfunding'];
        item.items = orderBy(item.items, (i) => toolsOrder.indexOf(i.name));
      }
      if (item.name === 'Manage Teams') {
        const toolsOrder = ['Teams', 'Users'];
        item.items = orderBy(item.items, (i) => toolsOrder.indexOf(i.name));
      }
      return item;
    });
    this.navItems = this.navItems.filter((item: Navigation) => !item.hide);
    this.toggleCurrentService();
  }

  public toggleGroupHeight(group: string) {
    switch (group) {
      case 'Assigned':
        this.assignedProjectsCollapsed = !this.assignedProjectsCollapsed;
        break;
      case 'Bookmarks':
        this.favoriteProjectsCollapsed = !this.favoriteProjectsCollapsed;
        break;
      case 'Global':
        this.globalCollapsed = !this.globalCollapsed;
        break;
      default:
        break;
    }
  }

  public getGroupMaxHeight(groupLength: NavigationItem[], showMore: boolean, groupCollapsed: boolean): string {
    if (groupCollapsed) {
      return '0';
    }

    return showMore ? (groupLength.length + 1) * 32 + 45 + 'px' : '8.125rem';
  }

  public toggleProjectHeight(nav: string) {
    switch (nav) {
      case 'Assigned Projects':
        this.assignedProjectsShowMore = !this.assignedProjectsShowMore;
        break;
      case 'Favorite Projects':
        this.favoriteProjectsShowMore = !this.favoriteProjectsShowMore;
        break;
      default:
        break;
    }
  }

  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  private toggleCurrentService(): void {
    // regex to get all url path parameters
    const currentService = this.currentPath.split('/').slice(2);
    if (currentService && currentService[1]) {
      this.deactivateLinks();
      switch (currentService[1]) {
        case 'operations':
          this.activateCurrentPage('Operations', currentService);
          break;
        case 'collaboration':
          this.activateCurrentPage('Collaboration', currentService);
          break;
        case 'development':
          this.activateCurrentPage('Development', currentService);
          break;
        case 'tools':
          this.activateCurrentPage('LFX Tools', currentService);
          break;
        case 'manage-teams':
          this.activateCurrentPage('Manage Teams', currentService);
          break;
        case 'reports':
          this.activateCurrentPage('Reports', currentService);
          break;
      }
    }
  }

  private setProject(): void {
    this.project$ = this.projectService.currentProject$.pipe(
      tap((project) => {
        if (project) {
          this.projectID = project.ID;
          this.setNavItems();
        }
      })
    );
  }

  private initializeHomeSidebar(): void {
    const params = new HttpParams({ fromObject: { basic: true } });
    this.user$ = this.userService.me(params).pipe(
      take(1),
      tap(() => {
        this.fetchFavoriteProjects(false);
        this.fetchAssignedProjects(false);
      })
    );
  }

  private activateCurrentPage(indexName: string, currentService: string[]) {
    const index = this.navItems.findIndex((navItem: Navigation) => navItem.name === indexName);
    if (this.navItems[index]) {
      this.navItems[index].active = true;
      this.navItems.forEach((navItem: Navigation) => {
        navItem.items = navItem.items.map((n: ProjectPages) => {
          const item = n;
          item.active = currentService[2].includes(item.slug?.split('/')[4]);

          return item;
        });
      });
    }
  }

  private deactivateLinks(): void {
    this.navItems.forEach((nav: Navigation) => {
      nav.active = false;
      nav.items.forEach((page: ProjectPages) => {
        page.active = false;
      });
    });
  }

  private fetchFavoriteProjects(refresh: boolean = true): void {
    if (this.favoriteProjects && this.favoriteProjects.length > 0 && !refresh) {
      this.favoriteProjects$ = of(
        this.userService.favoriteProjects
          .map((p) => ({ ...p, active: this.currentProjectID === p.ID }))
          .map((p: FavoriteProject) => ({
            name: p.Name,
            slug: p.Slug,
            favorite: true,
            id: p.ID,
            active: this.currentProjectID === p.ID
          }))
      );
      return;
    }

    this.favoriteProjects$ = this.userService.getUserFavoriteProjects().pipe(
      switchMap(() => this.userService.favoriteProjects$),
      map(() => {
        this.favoriteProjectsID = this.userService.favoriteProjectsID;
        this.favoriteProjects = this.userService.favoriteProjects;

        return this.favoriteProjects.map((p: FavoriteProject) => ({
          name: p.Name,
          slug: p.Slug,
          favorite: true,
          id: p.ID,
          active: this.currentProjectID === p.ID
        }));
      })
    );
  }

  private fetchAssignedProjects(refresh: boolean = true): void {
    if (this.assignedProjects && !refresh) {
      this.assignedProjects$ = of(this.assignedProjects.map((p) => ({ ...p, active: this.currentProjectID === p.id })));
      return;
    }

    const params = new HttpParams({
      fromObject: { pageSize: '9999', view: 'pcc', orderBy: 'name', myProjects: true, basic: true, excludeCategories: true, onlyProjectScope: true }
    });
    this.assignedProjects$ = this.projectService.getProjectSummary(params).pipe(
      map((projects) => {
        const flattenedProjects = flattenSummaryData(projects);
        this.userService.assignedProjects = flattenedProjects;

        this.assignedProjects = flattenedProjects.map((p: ProjectSummary) => ({
          name: p.Name,
          slug: p.Slug,
          favorite: false,
          id: p.ID,
          active: this.currentProjectID === p.ID
        }));

        return this.assignedProjects;
      })
    );
  }
}
