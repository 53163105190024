<div class="search-projects">
  <div class="search-wrapper" [formGroup]="form">
    <p-autoComplete
      formControlName="search"
      [suggestions]="projects"
      (onSelect)="onSelect($event)"
      [delay]="500"
      [minLength]="3"
      [autofocus]="false"
      class="py-0"
      field="Name"
      placeholder="Search for a project"
      (completeMethod)="onSearch($event)"
      icon="fas fa-search"
      [showEmptyMessage]="true"
      [completeOnFocus]="form.value.sidebarSearch">
      <ng-template let-project pTemplate="item">
        <div class="flex align-items-center gap-2 level-{{ project.level }}">
          <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">{{ project.Name }}</div>
        </div>
      </ng-template>
      <ng-template pTemplate="empty">
        <i class="text-13 items-center flex fas fa-exclamation-circle"></i>
        <div class="text-sm">Project Not Found</div>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
