import { noop } from '@datadog/browser-core';
export function createPreStartStrategy() {
  let status = 0 /* PreStartRecorderStatus.None */;
  return {
    strategy: {
      start() {
        status = 1 /* PreStartRecorderStatus.HadManualStart */;
      },
      stop() {
        status = 2 /* PreStartRecorderStatus.HadManualStop */;
      },
      isRecording: () => false,
      getSessionReplayLink: noop
    },
    shouldStartImmediately(configuration) {
      return status === 1 /* PreStartRecorderStatus.HadManualStart */ || status === 0 /* PreStartRecorderStatus.None */ && !configuration.startSessionReplayRecordingManually;
    }
  };
}
