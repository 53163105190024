<div class="contact-support-container">
  <p class="font-bold">Please click on the following link to contact support</p>
  <p>
    <a href="https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4/create/358" target="_blank"
      >https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4/create/358</a
    >
  </p>
  <p class="font-bold">Issue Details</p>
  <p>Please add the following information in your ticket description to allow our support team to assist you better</p>
  <div class="stack-trace">
    <pre><code>{{ errorStack | json }}</code></pre>
  </div>
  <div class="flex justify-start">
    <button pButton class="p-button-primary flex" [cdkCopyToClipboard]="errorStack | json" appendTo="body" (click)="onClickCopy()">
      {{ copyText }}
    </button>
  </div>
</div>
