import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InitialPipe } from '@app/shared/pipes/initial.pipe';
import { ITXService } from '@app/shared/services/itx.service';
import { ProjectService } from '@app/shared/services/project.service';
import { UserService } from '@app/shared/services/user.service';
import { flattenSummaryData } from '@app/shared/utils/helper';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomainSearch, PaginatedResponse, Project, ProjectSummary, RecentlyViewedProject, User } from 'lfx-pcc';
import { cloneDeep, compact, isEmpty, map as lodashMap, orderBy } from 'lodash';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AutoFocusModule } from 'primeng/autofocus';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, shareReplay, tap } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-global-search',
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    AutoFocusModule,
    SkeletonModule,
    RouterModule,
    TagModule,
    TooltipModule,
    ScrollPanelModule,
    OverlayPanelModule,
    TabViewModule,
    AvatarModule,
    InitialPipe,
    AccordionModule,
    InputTextModule
  ],
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  @ViewChild('op', { static: false }) public readonly overlayPanel: OverlayPanel;
  @ViewChild('searchInput', { static: false }) public readonly searchInput: ElementRef<HTMLInputElement>;

  public form: FormGroup;
  public searchUser$: Observable<User[]> | null;
  public searchProjects$: Observable<ProjectSummary[]> | null;
  public searchDomains$: Observable<DomainSearch[]> | null;
  public projects$: Observable<ProjectSummary[]> | null;
  public projects: ProjectSummary[] = [];
  public recentlyViewed$: Observable<RecentlyViewedProject[]>;
  public domains$: Observable<DomainSearch[]>;
  public originalSiteURL: string = environment.originalSiteURL;
  public project$: Observable<Project | null>;
  public projectsCount: number = 0;
  public usersCount: number = 0;
  public domainsCount: number = 0;
  public loadingProjects: boolean = false;
  public loadingUsers: boolean = false;
  public loadingDomains: boolean = false;
  public projectSearchError: boolean = false;
  public userSearchError: boolean = false;
  public domainSearchError: boolean = false;

  private fb: FormBuilder = inject(FormBuilder);
  private projectService: ProjectService = inject(ProjectService);
  private userService: UserService = inject(UserService);
  private itxService: ITXService = inject(ITXService);
  private messageService: MessageService = inject(MessageService);

  public constructor() {
    this.initRecentSearch();

    this.form = this.fb.group({
      search: ['']
    });

    this.form.controls.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
      this.search(value);
    });
  }

  public ngOnInit(): void {
    this.initRecentSearch();
    this.project$ = this.projectService.currentProject$;
  }

  public initRecentSearch(): void {
    this.recentlyViewed$ = this.userService.recentlyViewedProjects$ as Observable<RecentlyViewedProject[]>;
  }

  public get hasSearchValue(): boolean {
    return (this.form.controls.search?.value?.trim().length ?? 0) > 1;
  }

  public search(value: string): void {
    this.loadingProjects = true;
    this.loadingUsers = true;
    this.loadingDomains = true;
    const query = value && value.trim();
    if (this.hasSearchValue) {
      this.searchProjects$ = this.fetchProjectsFromSummary().pipe(
        map(() => {
          return this.handleSearch(query);
        }),
        tap((projects) => {
          this.projects = projects;
          this.projectsCount = projects.length || 0;
          this.loadingProjects = false;
        })
      );
      this.searchDomains$ = this.fetchDomains(query);
      // TODO: Reenable user search once we know what to do with it
      // this.searchUser$ = this.getUsers(query).pipe(
      //   map((users) => {
      //     return orderBy(users.Data, ['FirstName', 'LastName'], ['asc', 'asc']);
      //   })
      // );
    }
  }

  public onClickSearch(event: Event): void {
    this.initRecentSearch();
    this.overlayPanel.show(event);

    if (!this.overlayPanel.overlayVisible) {
      this.search(this.form.controls.search.value);
    }
  }

  public onSelect(): void {
    this.form.controls.search.setValue('');
    this.overlayPanel.hide();
  }

  private fetchDomains(query: string): Observable<DomainSearch[]> {
    if (!this.isDomainSearchTermValid(query)) {
      this.loadingDomains = false;
      return of([]);
    }
    const params = new HttpParams({ fromObject: { domain_name: query } });
    return this.itxService.getDomainSearch(params).pipe(
      catchError((error) => {
        console.error(error);
        return of({
          Data: [] as DomainSearch[],
          Metadata: {
            Offset: 0,
            PageSize: 0,
            TotalSize: 0
          }
        });
      }),
      map((response) => response.Data || []),
      tap((domains) => {
        this.domainsCount = domains.length || 0;
        this.loadingDomains = false;
      })
    );
  }

  private getUsers = (query: string): Observable<PaginatedResponse<User[]>> => {
    const userSearchParams = new HttpParams({ fromObject: { name: query } });
    return this.userService.searchUsers(userSearchParams).pipe(
      catchError((error) => {
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message
        });
        return of({
          Data: [],
          Metadata: {
            Offset: 0,
            PageSize: 0,
            TotalSize: 0
          }
        });
      }),
      tap((users) => {
        this.usersCount = users.Data?.length || 0;
        this.loadingUsers = false;
      })
    );
  };

  private fetchProjectsFromSummary(): Observable<ProjectSummary[]> {
    const params = new HttpParams({ fromObject: { pageSize: '9999', excludeCategories: true, view: 'pcc' } });
    return this.projectService.getProjectSummary(params, true).pipe(shareReplay(1));
  }

  private handleSearch(searchValue: string, exact: boolean = false): ProjectSummary[] {
    return flattenSummaryData(this.filterProjects(cloneDeep(this.projectService.projectsRaw()), searchValue, exact));
  }

  private filterProjects = (projects: ProjectSummary[], search: string, exact: boolean = false): ProjectSummary[] => {
    return orderBy(
      compact(
        lodashMap(projects, (p) => {
          if (
            (exact && p.Name.toLocaleLowerCase() === search.toLowerCase()) ||
            (exact && p.Slug && p.Slug.toLocaleLowerCase() === search.toLowerCase()) ||
            (exact && p.ID && p.ID === search) ||
            (!exact && search && p.Name.toLocaleLowerCase().includes(search.toLowerCase())) ||
            (!exact && search && p.Slug && p.Slug.toLocaleLowerCase().includes(search.toLowerCase())) ||
            (!exact && search && p.ID && p.ID === search)
          ) {
            // loop through this parent's projects and filter that as well
            if (p.Projects) {
              p.Projects = this.filterProjects(p.Projects, search);
            }
            return p;
          } else if (p.Projects) {
            const filteredList: ProjectSummary[] = this.filterProjects(p.Projects, search);
            if (isEmpty(filteredList)) {
              return null;
            }
            p.Projects = filteredList;
            return p;
          }
          return null;
        })
      ),
      'Name'
    );
  };

  private isDomainSearchTermValid(query: string): boolean {
    // Domain search should only be triggered if the query is not empty and does not contain inner spaces
    return !!query && !query.trim().includes(' ');
  }
}
