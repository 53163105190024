// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Project, ProjectSummary } from 'lfx-pcc';
import { compact, isEmpty, map as loMap, orderBy } from 'lodash';
import { Message, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, map, mergeMap, Observable, of, retry, switchMap, take, tap } from 'rxjs';

import { ContactSupportComponent } from './shared/components/contact-support/contact-support.component';
import { FeatureFlagService } from './shared/services/feature-flag.service';
import { MetaService } from './shared/services/meta.service';
import { ProjectService } from './shared/services/project.service';
import { ReportService } from './shared/services/report.service';
import { UserService } from './shared/services/user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public user$: Observable<User | null | undefined>;
  public project$: Observable<Project | null>;
  public projectID$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  public currentProjectID: string = '';
  public currentProjectID$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  public hideHeader: boolean = false;
  public hideSidebar: boolean = false;
  public isHealthMetrics: boolean = false;

  private userService: UserService = inject(UserService);
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private metaService: MetaService = inject(MetaService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private dialogService: DialogService = inject(DialogService);
  private projectService: ProjectService = inject(ProjectService);
  private featureService: FeatureFlagService = inject(FeatureFlagService);
  private reportService: ReportService = inject(ReportService);
  private messageService: MessageService = inject(MessageService);

  public constructor() {
    this.currentProjectID$.pipe(distinctUntilChanged()).subscribe((projectID) => {
      if (projectID) {
        this.projectService.currentProject$.next(null);
        this.project$ = this.userService.getProjectPermissions(projectID).pipe(
          retry({ count: 2, delay: 3000 }),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.message
            });

            this.router.navigate(['/not-available']);
            return of(error);
          }),
          switchMap(() => this.projectService.getProject(projectID, true, true))
        );
      }
    });
  }

  @HostListener('window:keydown', ['$event']) public handleKeyDown(event: KeyboardEvent) {
    if (event.metaKey && event.key === 'k') {
      this.reportService
        .clearCache(this.currentProjectID)
        .pipe(take(1))
        .subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Cache Cleared',
            detail: 'Report cache cleared successfully'
          });
        });
    }
  }

  public ngOnInit(): void {
    this.loadLFxHeaderScriptAndLinks();
  }

  public ngOnDestroy(): void {
    (window as any).$sleek.shutdown();
  }

  public ngAfterViewInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.hideHeader = false;
        this.hideSidebar = false;
        const projectPath = e.url.split('?')[0].split('/');
        this.redirectToV1(projectPath, e.url);
        if (projectPath && projectPath.length >= 2) {
          this.isHealthMetrics = projectPath.includes('health-metrics');

          if (
            (projectPath && projectPath.includes('all-projects')) ||
            projectPath.includes('not-found') ||
            projectPath.includes('global-surveys') ||
            projectPath.includes('global-committee-audit') ||
            projectPath.includes('project-formation') ||
            projectPath.includes('changelog') ||
            projectPath.includes('not-available')
          ) {
            this.currentProjectID = '';
            this.hideHeader = true;
            this.hideSidebar = projectPath.includes('not-available');
          } else {
            this.currentProjectID = projectPath[2];
          }
        }
      }

      if (e instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.hideHeader = false;
        const projectPath = e.urlAfterRedirects.split('?')[0].split('/');
        this.redirectToV1(projectPath, e.urlAfterRedirects);
        this.isHealthMetrics = projectPath.includes('health-metrics');
        if (
          projectPath &&
          (projectPath.includes('all-projects') ||
            projectPath.includes('not-found') ||
            projectPath.includes('global-surveys') ||
            projectPath.includes('global-committee-audit') ||
            projectPath.includes('project-formation') ||
            projectPath.includes('changelog') ||
            projectPath.includes('not-available'))
        ) {
          this.currentProjectID = '';
          this.hideHeader = true;
          this.hideSidebar = projectPath.includes('not-available');
        } else {
          if (projectPath.length >= 3) {
            this.currentProjectID = projectPath[2];
          }
        }

        this.projectID$.next(this.currentProjectID || null);
        this.currentProjectID$.next(this.currentProjectID || null);
        if (this.currentProjectID) {
          this.userService.setRecentlyViewedProjects(this.currentProjectID);
          localStorage.setItem('currentProjectID', this.currentProjectID);
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event) => {
        this.metaService.setTitle(event.title);
      });
  }

  public onContactSupport(event: Message): void {
    this.dialogService.open(ContactSupportComponent, {
      header: 'Contact Support',
      dismissableMask: true,
      styleClass: 'contact-support-dialog',
      data: {
        projectID: this.currentProjectID,
        error: event.data
      }
    });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private loadLFxHeaderScriptAndLinks() {
    this.setScript().then(() => {
      const header: any = document.getElementById('lfx-header');

      datadogRum.init({
        applicationId: '2171b650-b0c8-4236-9249-0b91170b4df9',
        clientToken: 'pub4dcd440df14efd4c777e6ca817056c42',
        site: 'datadoghq.com',
        service: 'lfx-pcc-spa',
        env: environment.datadogEnv,
        traceSampleRate: environment.datadogEnv ? 100 : 0,
        sessionSampleRate: environment.datadogEnv ? 100 : 0,
        sessionReplaySampleRate: environment.datadogEnv ? 100 : 0,
        telemetrySampleRate: environment.datadogEnv ? 100 : 0,
        trackUserInteractions: true,
        allowedTracingUrls: environment.traceOrigins,
        defaultPrivacyLevel: 'allow',
        version: environment?.appVersion
      });

      this.user$ = this.authService.user$.pipe(
        tap((user) => {
          if (environment.datadogEnv) {
            datadogRum.startSessionReplayRecording();
          }

          if (user) {
            this.featureService.initialize(user as User);

            header.authuser = user;
            datadogRum.setUser({
              id: user['https://sso.linuxfoundation.org/claims/username'],
              email: user.email,
              name: user.name
            });

            if (environment.environment === 'prod') {
              (window as any).$sleek.setUser({
                token: user['https://sso.linuxfoundation.org/claims/sleekplan']
              });
            }
          }
        })
      );
    });
  }

  /**
   * filterProjects - filter projects and it's sub projects based on the search string
   * This is different from the filtering on the admin dashboard as this should not include the child projects
   * of parents that matches the search string
   *
   * @param projects
   * @param regex
   */
  private filterProjects = (projects: ProjectSummary[], search: string): ProjectSummary[] =>
    orderBy(
      compact(
        loMap(projects, (p) => {
          if (p.Name.toLocaleLowerCase().includes(search.toLowerCase()) || (p.Slug && p.Slug.toLocaleLowerCase().includes(search.toLowerCase()))) {
            // loop through this parent's projects and filter that as well
            if (p.Projects) {
              p.Projects = this.filterProjects(p.Projects, search);
            }
            return p;
          } else if (p.Projects) {
            const filteredList: ProjectSummary[] = this.filterProjects(p.Projects, search);
            if (isEmpty(filteredList)) {
              return null;
            }
            p.Projects = filteredList;
            return p;
          }
          return null;
        })
      ),
      'Name'
    );

  private async setScript(): Promise<any> {
    (window as any).$sleek = [];
    (window as any).SLEEK_PRODUCT_ID = environment.sleekplanId;
    return await Promise.all([this.injectScript('https://client.sleekplan.com/sdk/e.js'), this.injectScript(environment.lfxHeader + '/lfx-header-v2.js')]);
  }

  private injectScript(src: string): Promise<any> {
    if (this.isScriptLoaded(src)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', (e) => reject(e.error));
      document.head.appendChild(script);
    });
  }

  private isScriptLoaded(url: string): boolean {
    const scripts = document.getElementsByTagName('script');

    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === url) {
        return true;
      }
    }

    return false;
  }

  private redirectToV1(path: string[], url: string): void {
    if (path.length > 3 && path[1] === 'project' && path[2] !== '') {
      const projectRegex = new RegExp(
        // eslint-disable-next-line max-len
        '/(?:project)/(.*?)/(operations|collaboration|development|tools)/(domains|source-control|ci|insights|security|easycla)(.*)'
      );
      // Navigate to the new URL using window.location.href
      const match = url.match(projectRegex);
      if (match) {
        window.location.href = `${environment.originalSiteURL}/project/${match[1]}/${match[2]}/${match[3]}${match[4] ?? ''}`;
      }
    }
  }
}
