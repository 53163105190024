<div class="navigation-item" [class.active]="item.active" [routerLinkActive]="'/'" data-cy="sidenav-group-items">
  <a [routerLink]="getSlug(item)" [routerLinkActive]="getSlug(item)" [class.active]="true" ellipsis>
    <span>{{ item.name }}</span>
  </a>
  <i
    class="fa-star"
    [ngClass]="item.favorite ? 'fa-solid' : 'fa-light'"
    (click)="toggleFavorite()"
    *ngIf="showFavorite"
    appendTo="body"
    [pTooltip]="item.favorite ? 'Remove Bookmark' : 'Bookmark'"></i>
</div>
