import { CommonModule, DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ExportDataComponent } from '@app/shared/components/export-data/export-data.component';
import { InitialPipe } from '@app/shared/pipes/initial.pipe';
import { ProjectService } from '@app/shared/services/project.service';
import { COMMITTEE_LOGS_FIELD_MAPPING } from '@app/shared/utils/activity-logs';
import { COMMITTEE_TYPE, COMMITTEE_ACTION, RECORD_SHOW_OPTIONS } from '@app/shared/utils/constants';
import { exportToCSV } from '@app/shared/utils/helper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CalendarModule } from 'angular-calendar';
import { AuditTableData, Committee, CommitteeAuditLog, CommitteeAuditUser } from 'lfx-pcc';
import { uniqBy } from 'lodash';
import { MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Observable, catchError, of, take, tap } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-committee-audit-log',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InitialPipe,
    MultiSelectModule,
    AvatarModule,
    SkeletonModule,
    TableModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    ExportDataComponent
  ],
  templateUrl: './committee-audit-log.component.html',
  styleUrl: './committee-audit-log.component.scss',
  providers: [DatePipe]
})
export class CommitteeAuditLogComponent implements OnInit {
  public committeeId: string | null;
  public committeesAuditLogs$: Observable<CommitteeAuditLog[]>;
  public committee$: Observable<Committee>;
  public committee: Committee;
  public tableHeight: string = window.innerHeight - 180 + 'px';
  public auditLogTableData: AuditTableData[] = [];
  public filterUsers: CommitteeAuditUser[] = [];
  public activityLogMapping: { field: string; value: string }[] = COMMITTEE_LOGS_FIELD_MAPPING;
  public rangeOptions = RECORD_SHOW_OPTIONS;
  public typeFilter: any[] = COMMITTEE_TYPE;
  public actionFilter: any[] = COMMITTEE_ACTION;
  public form: FormGroup;
  public hideFields: string[] = ['sfid', 'systemmodstamp', 'lastmodifiedbyid', 'lastmodifieddate', 'createddate', 'createdbyid'];
  public hasError: boolean = false;

  private route: Router = inject(Router);
  private projectService: ProjectService = inject(ProjectService);
  private messageService: MessageService = inject(MessageService);
  private datePipe: DatePipe = inject(DatePipe);
  private fb: FormBuilder = inject(FormBuilder);
  private dialogService: DialogService = inject(DialogService);

  public constructor() {
    this.form = this.fb.group({
      period: ['all']
    });
  }

  public ngOnInit(): void {
    // Activated route not works here so used this.
    this.committeeId = this.route.url.split('collaboration/committees')[1].replace('/', '');

    if (this.committeeId) {
      this.committee$ = this.projectService.getCommittee(this.projectService.currentProject.ID, this.committeeId).pipe(
        tap((committee: Committee) => {
          this.committee = committee;
        })
      );
      // Get committee specific audit log
      this.getCommitteeAuditLog();
    } else {
      // Get all committee's audit log
      this.getAllCommitteesAuditLog();
    }
  }

  public onChange(): void {
    if (this.committeeId) {
      this.getCommitteeAuditLog();
    } else {
      this.getAllCommitteesAuditLog();
    }
  }

  public onImageError(event: Event): void {
    const element = event.target as HTMLImageElement;
    element.src = 'assets/images/user-default.svg';
    element.classList.add('object-contain');
  }

  public onExport(): void {
    const exportFields = ['Date', 'Committee', 'Type', 'Action', 'Members', 'Field', 'From', 'To', 'User'];
    const fieldOptions: { label: string; value: string; checked: boolean }[] = exportFields.map((field) => ({ label: field, value: field, checked: true }));
    this.dialogService
      .open(ExportDataComponent, {
        header: 'Export Committees Audit Log',
        dismissableMask: true,
        data: {
          projectId: this.projectService.currentProject.ID,
          action: `export-${this.projectService.currentProject.Slug}-${this.committee?.Name || 'committees'}-audit-log`,
          fieldOptions,
          project: this.projectService.currentProject
        }
      })
      .onClose.pipe(take(1))
      .subscribe((fields) => {
        if (fields && fields.length > 0) {
          this.exportAuditLog(fields);
        }
      });
  }

  private exportAuditLog(selectedColumns: string[]): void {
    const data: any[] = [];
    const columnMap = this.getColumnMap();
    this.auditLogTableData.forEach((log: AuditTableData) => {
      const rowData: any = {};

      selectedColumns.forEach((column) => {
        if (columnMap[column]) {
          rowData[column] = columnMap[column](log);
        }
      });

      data.push(Object.values(rowData));
    });
    exportToCSV(data, `${this.projectService.currentProject.Slug}-${this.committee?.Name || 'committees'}-audit-log`, selectedColumns);
  }

  private getCommitteeAuditLog(): void {
    const params = new HttpParams({
      fromObject: { period: this.form.controls.period.value }
    });
    this.committeesAuditLogs$ = this.projectService.getCommitteeAuditLog(this.projectService.currentProject.ID, this.committeeId || '', params).pipe(
      catchError((error) => {
        this.hasError = true;
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get committee audit log. Please try again or contact support.',
          data: error,
          key: 'support'
        });

        return of();
      }),
      tap((logs: CommitteeAuditLog[]) => {
        if (logs) {
          this.processTableData(logs);
        } else {
          this.hasError = true;
        }
      })
    );
  }

  private getAllCommitteesAuditLog(): void {
    const params = new HttpParams({
      fromObject: { period: this.form.controls.period.value }
    });
    this.committeesAuditLogs$ = this.projectService.getCommitteesAuditLog(this.projectService.currentProject.ID, params).pipe(
      catchError((error) => {
        this.hasError = true;
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get committees audit log. Please try again or contact support.',
          data: error,
          key: 'support'
        });

        return of();
      }),
      tap((logs: CommitteeAuditLog[]) => {
        if (logs) {
          this.processTableData(logs);
        } else {
          this.hasError = true;
        }
      })
    );
  }

  private processTableData(auditLogs: CommitteeAuditLog[] = []): void {
    this.auditLogTableData = []; // Reset it for search and filter cases.
    this.filterUsers = []; // Reset users filter
    auditLogs.map((log: CommitteeAuditLog) => {
      log.AuditUser.Name = log.AuditUser.Name || log.AuditUser.UserName || '-';
      log.Changes.forEach((change) => {
        if (!this.hideFields.includes(change.Field)) {
          const from = change.From?.trim() || '-';
          const to = change.To?.trim() || '-';
          // Convert date to human readable form
          const obj = {
            createdAt: log.CreatedAt,
            filterDate: new Date(log.CreatedAt),
            field: this.getFieldName(change.Field) || '-',
            from: this.formatData(from),
            to: this.formatData(to),
            originalFrom: from,
            originalTo: to,
            auditUser: log.AuditUser,
            action: log.Action,
            memberName: log.MemberName || '-',
            committeeName: log.CommitteeName || '-',
            type: log.RecordType === 'COMMITTEEMEMBER' ? 'Committee Member' : 'Committee'
          };
          this.auditLogTableData.push(obj);
          this.filterUsers.push(log.AuditUser);
        }
      });
    });
    this.filterUsers = uniqBy(this.filterUsers, (obj) => obj.UserID);
  }

  private getFieldName(field: string): string {
    return this.activityLogMapping.find((option) => option.field === field)?.value || field.trim();
  }

  private formatData(data: string): string {
    // Required to covert some string into human readable format.
    if (data === 'nil') {
      return `-`;
    }

    if (this.isValidURL(data)) {
      // Convert to a link.
      return `<a class="text-primary" href="${data}" target="_blank">${data}</a>`;
    }

    return data;
  }

  private isValidURL(url: string): boolean {
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/\S*)?$/;
    return urlPattern.test(url);
  }

  private getColumnMap(): { [key: string]: (log: AuditTableData) => any } {
    return {
      Date: (log) => this.datePipe.transform(log.createdAt, 'dd MMM y h:mm a') || '-',
      Committee: (log) => log.committeeName || '-',
      Type: (log) => log.type || '-',
      Action: (log) => log.action || '-',
      Members: (log) => log.memberName || '-',
      Field: (log) => log.field || '-',
      From: (log) => log.originalFrom || '-',
      To: (log) => log.originalTo || '-',
      User: (log) => log.auditUser?.Name || log.auditUser?.Email || '-'
    };
  }
}
